import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-1 g-xl-12" }
const _hoisted_2 = { class: "col-xxl-2" }
const _hoisted_3 = { class: "col-xxl-2" }
const _hoisted_4 = { class: "col-xxl-2" }
const _hoisted_5 = { class: "col-xxl-2" }
const _hoisted_6 = { class: "col-xxl-2" }
const _hoisted_7 = { class: "row gy-5 g-xl-12" }
const _hoisted_8 = { class: "col-xxl-2" }
const _hoisted_9 = { class: "col-xxl-2" }
const _hoisted_10 = { class: "col-xxl-2" }
const _hoisted_11 = { class: "col-xxl-2" }
const _hoisted_12 = { class: "col-xxl-2" }
const _hoisted_13 = { class: "row gy-5 g-xl-12" }
const _hoisted_14 = { class: "col-xxl-2" }
const _hoisted_15 = { class: "col-xxl-2" }
const _hoisted_16 = { class: "col-xxl-2" }
const _hoisted_17 = { class: "col-xxl-2" }
const _hoisted_18 = { class: "col-xxl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidgetPower = _resolveComponent("MixedWidgetPower")!
  const _component_MixedWidget4 = _resolveComponent("MixedWidget4")!
  const _component_MixedWidget5 = _resolveComponent("MixedWidget5")!
  const _component_MixedWidget6 = _resolveComponent("MixedWidget6")!
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")!
  const _component_MixedWidget8 = _resolveComponent("MixedWidget8")!
  const _component_MixedWidget9 = _resolveComponent("MixedWidget9")!
  const _component_MixedWidget10 = _resolveComponent("MixedWidget10")!
  const _component_MixedWidget11 = _resolveComponent("MixedWidget11")!
  const _component_MixedWidget12 = _resolveComponent("MixedWidget12")!
  const _component_MixedWidget13 = _resolveComponent("MixedWidget13")!
  const _component_MixedWidget14 = _resolveComponent("MixedWidget14")!
  const _component_MixedWidget15 = _resolveComponent("MixedWidget15")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MixedWidgetPower, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MixedWidget4, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MixedWidget5, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MixedWidget6, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_MixedWidgetPower, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_MixedWidget8, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_MixedWidget9, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_MixedWidget11, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_MixedWidgetPower, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_MixedWidget12, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_MixedWidget13, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_MixedWidget14, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_MixedWidget15, {
          "widget-classes": "card-xxl-stretch-50 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "150"
        })
      ])
    ])
  ], 64))
}