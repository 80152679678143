
import { defineComponent, onMounted } from "vue";
import MixedWidget4 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget5 from "@/components/widgets/mixed/Widget7_1.vue";
import MixedWidget6 from "@/components/widgets/mixed/Widget7_2.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7_3.vue";

import MixedWidget8 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget9 from "@/components/widgets/mixed/Widget7_1.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget7_2.vue";
import MixedWidget11 from "@/components/widgets/mixed/Widget7_3.vue";

import MixedWidget12 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget13 from "@/components/widgets/mixed/Widget7_1.vue";
import MixedWidget14 from "@/components/widgets/mixed/Widget7_2.vue";
import MixedWidget15 from "@/components/widgets/mixed/Widget7_3.vue";

import MixedWidgetPower from "@/components/widgets/mixed/Widget_Power.vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    //TableWidget9,
    //TableWidget5,
    //ListWidget1,
    //ListWidget2,
    //ListWidget3,
    //ListWidget5,
    //ListWidget6,
    //MixedWidget2,
    MixedWidget4,
    MixedWidget5,
    MixedWidget6,
    MixedWidget7,
    MixedWidget8,
    MixedWidget9,
    MixedWidget10,
    MixedWidget11,
    MixedWidget12,
    MixedWidget13,
    MixedWidget14,
    MixedWidget15,
    MixedWidgetPower,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
  },
});
